const sen1 = "LAUNCHPAD.";
const sen2 = "STAKE.";
const sen3 = "BRIDGE.";
const sen4 = "CYBORD POOL.";
const sen5 = "EXCHANGE.";
const sen6 = "SWAP.";
const sen7 = "DAO.";
const sen8 = " DIGITAL ART.";

const ArrayData = [
    sen1,sen2,sen3,sen4,sen5,sen6,sen7,sen8,
];

export default ArrayData;
